@use "../../assets/css/variables.scss";

.footer {
  .footerFirst,
  .footerSecond {
    background: variables.$color-blue-2;
    color: variables.$color-white-2;
    @media only screen and (min-width: 900px) {
      .footerSocialIcons,
      .footerMenu {
        width: 70%;
        white-space: nowrap;
      }
    }
  }

  .socialTitle {
    white-space: nowrap;
  }

  .footerFirst {
    padding: 44px 130px 12px 130px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    .footerSocialIcons {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 18px;
      width: fit-content;
      padding-left: 50px;
      @media only screen and (max-width: 900px) {
        justify-content: flex-start;
        padding-left: 0px;
      }
    }
    a {
      margin-bottom: -5px;
      embed {
        width: 46px;
      }
    }
    a:last-child {
      padding-right: 0px;
    }
    @media only screen and (min-width: 900px) {
      align-items: center;
      flex-direction: row;
      padding: 44px 130px;
      justify-content: space-between;
    }
    @media only screen and (max-width: 900px) and (min-width: 601px) {
      padding: 32px 40px !important;
    }
    @media only screen and (max-width: 600px) {
      padding: 32px 24px !important;
    }
  }
  .footerHr {
    height: 1px;
    background: variables.$color-white-2;
  }
  .footerSecond {
    .footerCopy {
      font-size: 16px;
      white-space: nowrap;
    }
    .footerLogo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > img {
        width: fit-content;
      }
    }
    .footerSecondFlex {
      padding: 19px 130px;
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      .footerMenu {
        display: flex;
        justify-content: flex-end;
        a {
          padding-left: 36px;
          color: variables.$color-white-2;
          font-size: 16px;
          font-weight: bold;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        a:first-child{
          padding-left: 0px;
        }
      }

      flex-direction: row;
      align-items: center;
    }
  }
}
.footerSocialIcons img {
  width: 45px;
  height: 45px;
}
.logoLink {
  margin-right: 160px;
  margin: 0 auto;
}
@media only screen and (max-width: 900px) {
  .footerFirst {
    padding: 32px 24px !important;
  }
  .socialTitle {
    margin-bottom: 8px;
  }
  .footerMenu {
    margin-top: 0px !important;
  }
  .footerSecondFlex {
    flex-direction: column !important;
  }
  .footerMenu {
    a {
      margin-right: 16px;
      padding-left: 40px;
      white-space: nowrap;
    }
    margin-top: 12px !important;
    justify-content: flex-end;
  }
  .logoLink {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .footerMenu {
    a {
      margin-top: 12px;
      padding-left: 0px !important;
      margin-right: 0px !important;
    }
    margin-top: 0px !important;
    flex-direction: column !important;
    align-items: center;
  }
  .footerSocialIcons {
    padding-left: 0px !important;
    justify-content: center !important;
  }
  .socialTitle {
    font-weight: 500;
    text-align: center !important;
  }
}
