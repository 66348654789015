@mixin responsive($breakpoint) {
  @media only screen and (max-width: $breakpoint + px) {
    @content;
  }
}

@mixin hideScrollbar {
  //Hide scrollbar
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

@mixin customScrollbar($color) {
  scrollbar-color: $color transparent;
  scrollbar-width: thin;
  /* width */
  &::-webkit-scrollbar {
    $width-height: 8px;
    width: $width-height;
    height: $width-height;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $color, $alpha: 0.8);
    border-radius: 1px;
    &:hover {
      background: $color;
      width: 10px;
    }
  }

  @include responsive(900) {
    &::-webkit-scrollbar {
      $width-height: 5px;
      width: $width-height;
      height: $width-height;
    }
  }
  @include responsive(600) {
    @include hideScrollbar;
  }
}

@mixin itemsRow($gap) {
  & > :not(:last-child) {
    margin-right: $gap + px;
  }
}
@mixin itemsColumn($gap) {
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: $gap + px;
  }
}

@mixin responsive($breakpoint) {
  @media only screen and (max-width: $breakpoint + px) {
    @content;
  }
}

@mixin hideScrollbar {
  //Hide scrollbar
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

@mixin customScrollbar($color) {
  scrollbar-color: $color transparent;
  scrollbar-width: thin;
  /* width */
  &::-webkit-scrollbar {
    $width-height: 8px;
    width: $width-height;
    height: $width-height;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $color, $alpha: 0.8);
    border-radius: 1px;
    &:hover {
      background: $color;
      width: 10px;
    }
  }

  @include responsive(900) {
    &::-webkit-scrollbar {
      $width-height: 5px;
      width: $width-height;
      height: $width-height;
    }
  }
  @include responsive(600) {
    @include hideScrollbar;
  }
}

@mixin itemsRow($gap) {
  & > :not(:last-child) {
    margin-right: $gap + px;
  }
}
@mixin itemsColumn($gap) {
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: $gap + px;
  }
}

