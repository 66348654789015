@use "../../../assets/css/general.scss";
@use "../../../assets/css/variables.scss";

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content{
    padding: 40px;
    border:1px solid general.$light-blue-2;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cont{
    display: flex;
    justify-content: space-between;
}
.header{
    text-align: center;
}
.tbl{
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.btn{
    margin: 10px !important
}