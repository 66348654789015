@use "../../assets/css/variables.scss";
@use "../../assets/css/mixins.scss";

.header{
  height: 60px;
  background-color: variables.$color-blue-2;
  color: variables.$color-white-2;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
  @include mixins.responsive(500){
    padding: 10px 10px;
    justify-content: center;
    height: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
    text-align: center;
    margin-right: 20px;
    font-weight: 300;
    font-size: 14px;
    &:hover{
      text-decoration: underline;
    }
    @include mixins.responsive(450){
      font-size: 14px;
    }
    @include mixins.responsive(390){
      margin-right: 0px;
    }
  }
  
  .active{
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }
}