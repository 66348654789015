$color-white-1: #f6f6f6;
$color-white-2: #ffffff;

$color-gray--1: #6e6e6e;
$color-gray--2: #b7b7b7;
$color-gray--3: #e5e5e5;
$color-gray--4: #e2e2e2;
$color-lightgray: lightgray;

$color-dark--1: #030303;
$color-dark--2: #333;
$color-dark--3: #414141;

$color-orange-1: #e87224;

$color-blue-1: #305ea7;
$color-blue-2: #079dd5;
$color-blue-3: #009cd6;
$color-blue-4: #1582c6;
$color-blue-5: #e6ffff;

$color-pink-1: #bd8ed8;

$color-title: #2f2f2f;

$color-border: #00000038;
$color-dark-blue: #1582c633;
//media sizes
$mobile: 320px;
$tablet: 900px;
$large: 1415px;
