/*************** ***************
* ## Fonts
*/

@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url('../fonts/AvenirNextLTPro-Demi.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url('../fonts/AvenirNextLTPro-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

/*************** ***************
* ## Variables
*/

//Fonts
$font-main: 'AvenirNextLTPro-Demi';
$font-bold: 'AvenirNextLTPro-Bold';

//Main Colors
$dark-gray: #3C3C3C;
$light-gray: #b1b1b1;
$light-gray-2: #f3f3f3;
$light-blue: #079DD5; 
$light-blue-2: #0e9ed4;
$black: #000;
$white: #fff;
$color-blue-2: #079DD5;
$color-blue-3: #1582C6;
$color-white-2: #ffffff;

$color-gray--2: #b7b7b7;

$color-dark--1: #030303;
$color-dark--2: #333;

$color-backdrop: rgba(0, 0, 0, 0.5);

//Desktop / Mobile View threshold Screen Resolution
$small-width: 600px;
$medium-width: 800px;
$large-width: 900px;
$XL-width: 1190px;

/*************** ***************
* ## Mixins
*/

//flexbox
@mixin flex {
  display: flex;
  flex-direction: column;
}

/*************** ***************
* ## General Styles
*/
* {
  font-family: $font-main;
  transition: .1s ease;
}

html, body {
  margin: 0;
  background-color: $light-gray-2;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}