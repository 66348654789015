@use "../../assets/css/general.scss";

.notFound {
  width: calc(100% - 500px);
  height: calc(100vh - 613px);
  margin: 150px auto 170px;
  color: general.$light-blue-2;
}

.text {
  width: 100%;
  text-align: center;
}

.bold {
  font-size: 80px;
  font-weight: 600;
}

.pageGoneText {
  font-size: 44px;
}

.goto {
  margin-top: 20px;
  font-size: 22px;
  text-align: center;
  a {
    text-decoration: underline;
    color: inherit;
  }
}
