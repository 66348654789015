.sheetCont {
  padding: 20px;
}

.filters {
  //display: flex;
  margin: 20px;
}

.container {
  padding: 20px;
  width: fit-content;
}

.btn {
  margin: 20px !important
}

.save {
  position: fixed !important;
  top: 100px !important;
  right: 60px !important;
  padding-top: 11px !important;
}

.create {
  padding-top: 11px !important;
  position: fixed !important;
  top: 100px !important;
  right: 180px !important
}
.getData {
  padding-top: 11px !important;
  position: fixed !important;
  top: 100px !important;
  right: 330px !important
}

.input {
  position: fixed !important;
  top: 100px !important;
  right: 450px !important;
  width: 100px;
}

.inputProps{
  max-height: 45px;
}

.center {
  display: flex;
  justify-content: center;
}

.inputNumber {
  -moz-appearance: textfield;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}