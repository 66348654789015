@use "src/assets/css/mixins.scss";

.container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f6f6f6;
    overflow: auto;
  }
}
