@use "../../assets/css/general.scss";
@use "../../assets/css/mixins.scss";

$cell-width: 100px;
$cell-height: 20px;

*{
  box-sizing: border-box;
  transition: none !important;
}

.sheetWrapper{
  width: 100%;
  height: calc(100vh - 326px);
  overflow: auto;
  background-color: rgb(255, 255, 255);
  user-select: none;
}

.sheetActions{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
}


.sheetContainer {
  // with wrapper
  width: 100%;
  overflow-y: hidden;
  background-color: white;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: auto;

  // own container
  // width: calc(100% - 200px);
  // height: calc(100vh - 70px);
  // width: fit-content;
  // overflow-y: scroll;
  // background-color: rgb(0, 0, 0);
  // user-select: none;
  .row {
    display: flex;
    flex-wrap: nowrap;

    .cell {
      width: $cell-width;
      height: 100%;
      border: none;
      border-top: 1px solid rgb(114, 114, 114);
      border-left: 1px solid rgb(114, 114, 114);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 5px;
      .controll {
        position: absolute;
        right: 0px;
        height: 100%;
        width: 2px;
        &:hover {
          cursor: col-resize;
        }
      }
    }
  }
}

.sheetFooter{
  background-color: #fff;
}

.columnHeader, .rowsFirstCell{
  text-align: center;
}

.columnHeader{
    background-color: #f8f9fa;
}

.rowsFirstCell{
    background-color: #e8eaed;   
    width: 50px !important; 
}

.selectedCell{
    background-color: #e7f0fd;
}
.unselectedCell{
  background-color: inherit;
}

.cellInput{
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  border: 1px solid general.$color-blue-2;
  outline: 1px solid general.$color-blue-2;
}
