/* TODO optimize css */
// @font-face {
//   font-family: "AvenirNextLTPro";
//   src: url("./assets/fonts/AvenirLTProMedium.otf");
// }
@import "./assets/css/font-face.scss";


html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  font-family: "Avenir", serif !important;
}

body {
  scroll-behavior: smooth !important;
  margin: 0;
  font-family: "Avenir", serif !important;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fallback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.caption {
  padding: 10px;
  margin-bottom: 20px;
}

.bb {
  border: 1px solid black !important;
}

.bw {
  border: 1px solid white !important;
}

.clickable {
  cursor: pointer !important;
}

.centerH {
  margin: 0px auto;
}

.p-relative {
  position: relative !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.ma-0 {
  margin: 0 !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.pa-0 {
  padding: 0 !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.country-cards-container {
  padding: 20px 12px;
  background-color: #f3f3f3;
  scroll-behavior: smooth !important;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .d-sm-none {
    display: none;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mb-sm-12 {
    margin-bottom: 48px !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

}

@media screen and (min-width: 1150px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .ml-md-3 {
    margin-left: 12px !important;
  }

  .sticky-sidebar {
    position: sticky;
    top: -50px;
  }
}
