.sheetCont{
    padding: 20px;
    position: relative;
}


.save{
    position: fixed !important;
    top: 100px !important;
    right: 60px !important;
}

.loadingCont{
    position: absolute;
    top: -50px;
    right: 50px;
}