@use "../../assets/css/general.scss";

.backdrop{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  background-color: general.$color-backdrop !important
}

.loading {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  color: general.$light-blue !important;
  z-index: 99999;
}
