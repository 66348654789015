  @font-face {
    font-display: block;
    font-family: "Avenir";
    font-stretch: normal;
    font-weight: normal;
    src: url("../fonts/AvenirNextLTPro-Light.otf");
  }
  
  @font-face {
    font-display: block;
    font-family: "Avenir";
    font-stretch: normal;
    font-weight: 500;
    src: url("../fonts/AvenirNextLTPro-Medium.otf");
  }
  
  @font-face {
    font-display: block;
    font-family: "Avenir";
    font-stretch: normal;
    font-weight: 600;
    src: url("../fonts/AvenirNextLTPro-Demi.otf");
  }
  
  @font-face {
    font-display: block;
    font-family: "Avenir";
    font-stretch: normal;
    font-weight: bold;
    src: url("../fonts/AvenirNextLTPro-Bold.otf");
  }
  
  @font-face {
    font-display: block;
    font-family: "Avenir";
    font-stretch: condensed;
    font-weight: normal;
    src: url("../fonts/AvenirNextLTPro-UltLtCn.otf");
  }
  